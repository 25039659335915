<template>

  <div>

    <add-new :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive" :user-data="userData"
      @refetch-data="refetchData" />


    <!-- Table Container Card -->



    <b-row class="match-height">
      <!-- Company Table Card -->
      <b-col lg="3">
        <!-- siji -->
        <b-row class="match-height">
          <b-col cols="12">
            <!-- siji -->
            <b-card>

              <div class="m-2" style="height:378px">
                <template>
                  <div style="text-align: center;"> <b> COP Chiller 1</b> </div><br>
                  <div ref="gaugeChart" style="width: 110%; height: 400px;text-align: right;"></div>
                </template>
              </div>
            </b-card>
          </b-col>

          <b-col cols="12">
            <b-card no-body class="mb-0">

              <div class="m-2">
                <template>
                  <table style="width: 100%">
                    <tr>
                      <td> <b>Time </b> </td>
                      <td> {{ (COP1_detail.length > 0) ? COP1_detail[0].Tanggal : null }}</td>
                    </tr>
                    <br>
                    <tr>
                      <td> <b>Tin(℃) </b> </td>
                      <td> {{ (COP1_detail.length > 0) ? COP1_detail[0].Tin : null }}</td>
                    </tr>
                    <br>
                    <tr>
                      <td> <b>Tout(℃) </b> </td>
                      <td> {{ (COP1_detail.length > 0) ? COP1_detail[0].Tout : null }}</td>
                    </tr>

                    <br>
                    <tr>
                      <td> <b>△T(℃) </b> </td>
                      <td> {{ (COP1_detail.length > 0) ? COP1_detail[0].T : null }}</td>
                    </tr>
                    <br>
                    <tr>
                      <td> <b>Cool(kW) </b> </td>
                      <td> {{ (COP1_detail.length > 0) ? COP1_detail[0].Cooling_cap : null }}</td>
                    </tr>
                    <br>
                    <tr>
                      <td> <b>I(A) </b> </td>
                      <td> {{ (COP1_detail.length > 0) ? COP1_detail[0].I : null }}</td>
                    </tr>
                    <br>
                    <tr>
                      <td> <b>Pow(kW) </b> </td>
                      <td> {{ (COP1_detail.length > 0) ? COP1_detail[0].Elec_Pow2 : null }}</td>
                    </tr>
                  </table>
                </template>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-col>

      <b-col lg="9">

        <b-col lg="12">
          <!-- siji -->
          <b-card no-body class="mb-0">
            <br>
            <div style="text-align: center;"> <b>Realtime Data Chiller 1 </b> </div>

            <div class="m-2">
              <apexchart width="100%" height="400px" type="line" :options="chartOptions1" :series="series1" />
            </div>

            <div class="m-2">
              <apexchart width="100%" height="400px" type="line" :options="chartOptions2" :series="series2" />
            </div>

            <div class="m-2">
              <apexchart width="100%" height="400px" type="line" :options="chartOptions3" :series="series3" />
            </div>

            <div class="m-2">
              <apexchart width="100%" height="400px" type="line" :options="chartOptions4" :series="series4" />
            </div>
          </b-card>
        </b-col>
      </b-col>
    </b-row>

    <!-- iki chiller 2 -->
    <br>
    <br>

    <b-row class="match-height">
      <!-- Company Table Card -->
      <b-col lg="3">
        <!-- siji -->
        <b-row class="match-height">
          <b-col cols="12">
            <!-- siji -->
            <b-card>

              <div class="m-2" style="height:300px">
                <template>
                  <div style="text-align: center;"> <b> COP Chiller 2</b> </div><br>
                  <div ref="gaugeChart2" style="width: 100%; height: 400px;text-align: center;"></div>
                </template>
              </div>
            </b-card>
          </b-col>

          <b-col cols="12" style="height:385px">
            <b-card no-body class="mb-0">
              <div class="m-2">
                <template>
                  <table style="width: 100%">
                    <tr>
                      <td> <b>Time </b> </td>
                      <td> {{ (COP2_detail.length > 0) ? COP2_detail[0].Tanggal : null }}</td>
                    </tr>
                    <br>
                    <tr>
                      <td> <b>Tin(℃) </b> </td>
                      <td> {{ (COP2_detail.length > 0) ? COP2_detail[0].Tin : null }}</td>
                    </tr>
                    <br>
                    <tr>
                      <td> <b>Tout(℃) </b> </td>
                      <td> {{ (COP2_detail.length > 0) ? COP2_detail[0].Tout : null }}</td>
                    </tr>

                    <br>
                    <tr>
                      <td> <b>△T(℃) </b> </td>
                      <td> {{ (COP2_detail.length > 0) ? COP2_detail[0].T : null }}</td>
                    </tr>
                    <br>
                    <tr>
                      <td> <b>Cool(kW) </b> </td>
                      <td> {{ (COP2_detail.length > 0) ? COP2_detail[0].Cooling_cap : null }}</td>
                    </tr>
                    <br>
                    <tr>
                      <td> <b>I(A) </b> </td>
                      <td> {{ (COP2_detail.length > 0) ? COP2_detail[0].I : null }}</td>
                    </tr>
                    <br>
                    <tr>
                      <td> <b>Pow(kW) </b> </td>
                      <td> {{ (COP2_detail.length > 0) ? COP2_detail[0].Elec_Pow2 : null }}</td>
                    </tr>
                  </table>
                </template>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-col>

      <b-col lg="9">

        <b-col lg="12">
          <!-- siji -->
          <b-card no-body class="mb-0">
            <br>
            <div style="text-align: center;"> <b>Realtime Data Chiller 2 </b> </div>

            <div class="m-2">
              <apexchart width="100%" height="400px" type="line" :options="chartOptions5" :series="series5" />
            </div>

            <div class="m-2">
              <apexchart width="100%" height="400px" type="line" :options="chartOptions6" :series="series6" />
            </div>

            <div class="m-2">
              <apexchart width="100%" height="400px" type="line" :options="chartOptions7" :series="series7" />
            </div>
          </b-card>
        </b-col>
      </b-col>
    </b-row>


    <!-- iki bar chart  -->
    <br>
    <br>

    <b-row class="match-height">
      <b-col lg="6">
        <b-col lg="12">
          <b-card no-body class="mb-0">
            <br>
            <div style="text-align: center;"> <b>Chiller 1 </b> </div>

            <div class="m-2">
              <apexchart width="100%" height="400px" type="bar" :options="chartOptions8" :series="series8" />
            </div>
          </b-card>
        </b-col>
      </b-col>

      <b-col lg="6">
        <b-col lg="12">
          <b-card no-body class="mb-0">
            <br>
            <div style="text-align: center;"> <b>Chiller 2 </b> </div>

            <div class="m-2">
              <apexchart width="100%" height="400px" type="bar" :options="chartOptions9" :series="series9" />
            </div>
          </b-card>
        </b-col>
      </b-col>
    </b-row>

    <!-- iki data table -->
    <br>
    <br>
    <list-filters :role-filter.sync="roleFilter" :role-options="roleOptions" :status-filter.sync="statusFilter"
      :plan-filter.sync="planFilter" />


    <b-row class="match-height">
      <b-col lg="12">
        <b-card no-body class="mb-0">
          <div class="m-2">
            <b-row>
              <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                <label>Show</label>
                <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
                <label>entries</label>
              </b-col>

              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
                  <b-button variant="primary" @click="AddData()">
                    <span class="text-nowrap">Export</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>

          </div>

          <b-table ref="refUserListTable" class="position-relative" :items="fetchData" responsive :fields="tableColumns"
            :busy.sync="isBusy" primary-key="id" :sort-by.sync="sortBy" show-empty
            empty-text="No matching records found" :sort-desc.sync="isSortDirDesc" striped>

            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-button variant="outline-primary" disabled class="mr-1">
                  <b-spinner small />
                  Loading...
                </b-button>
              </div>
            </template>

            <template #cell(Tanggal)="data">
              <div class="text-nowrap">
                {{ data.item.Tanggal }}
              </div>
            </template>

          </b-table>
          <div class="mx-2 mb-2">
            <b-row>

              <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}
                  entries</span>
              </b-col>
              <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

                <b-pagination v-model="currentPage" :total-rows="totalData" :per-page="perPage" first-number last-number
                  class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <!-- <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                variant="primary"
                @click="AddData()"
              >
                <span class="text-nowrap">Add xxx</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchData"
        responsive
        :fields="tableColumns"
        :busy.sync="isBusy"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        striped
      >

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-button
              variant="outline-primary"
              disabled
              class="mr-1"
            >
              <b-spinner small />
              Loading...
            </b-button>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="text-nowrap">
            <div style="line-height:80%;">
              <br>
            </div>
            <feather-icon
              :id="`invoice-row-${data.item.id_lampu}-send-icon`"
              icon="EditIcon"
              class="cursor-pointer"
              :class="`text-primary`"
              size="17"
              @click="EditData(data.item)"
            />
            <b-tooltip
              :title="`Edit ${data.item.nama}`"
              class="cursor-pointer"
              :target="`invoice-row-${data.item.id_lampu}-send-icon`"
            />
            &nbsp;
            <feather-icon
              :id="`invoice-row2-${data.item.id_lampu}-send-icon`"
              icon="Trash2Icon"
              class="cursor-pointer"
              :class="`text-danger`"
              size="17"
              @click="showMsgBoxTwo(data.item.id_lampu)"
            />
            <b-tooltip
              :title="`Delete ${data.item.nama}`"
              class="cursor-pointer"
              :target="`invoice-row2-${data.item.id_lampu}-send-icon`"
            />
          </div>
          <div style="line-height:80%;">
            <br>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}
              entries</span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalData"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card> -->
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BPagination, BTooltip, BSpinner,
  // BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import ListFilters from './ListFilters.vue'
// eslint-disable-next-line import/no-cycle
import useList from './useList'
import StoreModule from '../StoreModule'
import AddNew from './AddNew.vue'
import VChart from 'vue-echarts'
import echarts from 'echarts';

export default {
  name: 'GaugeChart',
  props: {
    value: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      default: 100
    },
    min: {
      type: Number,
      default: 0
    },
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ListFilters,
    AddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BTooltip,
    BSpinner,
    // BDropdown,
    // BDropdownItem,
    BPagination,

    vSelect,
  },
  directives: {
    Ripple,
  },
  mounted() {
    // this.initChart();
    // this.initChart2();
  },
  watch: {
    value(newVal) {
      this.updateChart(newVal);
    },
    value(newVal) {
      this.updateChart2(newVal);
    },
  },
  data() {
    return {
      isBusy: false,
      chart: null,
      gaugeValue: 50,
      maxValue: 6,
    }
  },
  setup() {
    const roleOptions = [{ label: 'All Chiller', value: 'All' }, { label: 'Chiller 1', value: 'C1' }, { label: 'Chiller 2', value: 'C2' }]
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, StoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const blankTask = {
      kode_item: '',
      description: '',
      group_item: '',
      action: 'Add',
    }

    const isAddNewUserSidebarActive = ref(false)
    const userData = ref(JSON.parse(JSON.stringify(blankTask)))

    const EditData = taskData => {
      userData.value = taskData
      isAddNewUserSidebarActive.value = true
    }

    const AddData = async () => {
      try {
        const response = await axios({
          url: `${useAppConfig().AxiosUrl}api_mes/chillers-table-export/view?tanggal_from=${statusFilter.value}&tanggal_to=${planFilter.value}&chiller=${roleFilter.value}`,
          method: 'GET',
          responseType: 'blob', // penting untuk mendownload file
        });

        // Membuat URL untuk file
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;

        // Menentukan nama file dan menambahkan ke dokumen untuk klik
        link.setAttribute('download', 'Chillers.xlsx');
        document.body.appendChild(link);
        link.click();

        // Menghapus link dari dokumen
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error downloading the file', error);
      }
    }

    const Chillers = ref([])
    let COP1 = '5'
    let COP1_detail = []
    let COP2 = '4'
    let COP2_detail = []

    //Chiller 1 chart
    var chartOptions1 = ref()
    let categories1 = []
    let Tin1 = []
    let Tout1 = []
    let T1 = []
    let Flow1 = []
    const series1 = ref([])

    var chartOptions2 = ref()
    let I1 = []
    let V1 = []
    let Pow1 = []
    const series2 = ref([])

    var chartOptions3 = ref()
    let COP11 = []
    const series3 = ref([])

    var chartOptions4 = ref()
    let TT1 = []
    const series4 = ref([])

    //Chiller 2 chart
    var chartOptions5 = ref()
    let Tin2 = []
    let Tout2 = []
    let T2 = []
    let Flow2 = []
    const series5 = ref([])

    var chartOptions6 = ref()
    let I2 = []
    let V2 = []
    let Pow2 = []
    const series6 = ref([])

    var chartOptions7 = ref()
    let COP12 = []
    const series7 = ref([])


    //untuk bar chart COP
    let categoriesBar = []

    var chartOptions8 = ref()
    const series8 = ref([])

    var chartOptions9 = ref()
    const series9 = ref([])

    let COP321 = []
    let COP351 = []

    let COP322 = []
    let COP352 = []

    // Buat ref untuk elemen DOM
    const gaugeChart = ref(null);
    const gaugeChart2 = ref(null);

    onMounted(async () => {

      const result = await axios.get(`${useAppConfig().AxiosUrl}api_mes/chillers/view`)
      const dataAPI = result.data.data
      for (let i = 0, len = dataAPI.length; i < len; i++) {
        if (i + 1 === dataAPI.length) {
          COP2_detail.push(dataAPI[i])
          COP2 = dataAPI[i].COP1
        }
        if (i === len - 2) {
          COP1_detail.push(dataAPI[i])
          COP1 = dataAPI[i].COP1
        }

        // combo.push({ id: dataAPI[i].id_lampu, nama: dataAPI[i].nama }) tomi
        if (dataAPI[i].Chiller === 'C1') {
          categories1.push(dataAPI[i].Tanggal)
          Tin1.push(dataAPI[i].Tin)
          Tout1.push(dataAPI[i].Tout)
          T1.push(dataAPI[i].T)
          Flow1.push(dataAPI[i].Flow)

          I1.push(dataAPI[i].I)
          V1.push(dataAPI[i].V)
          Pow1.push(dataAPI[i].Elec_Pow2)
          COP11.push(dataAPI[i].COP1)

          TT1.push(dataAPI[i].Water_Tank_Temp)
        } else {
          Tin2.push(dataAPI[i].Tin)
          Tout2.push(dataAPI[i].Tout)
          T2.push(dataAPI[i].T)
          Flow2.push(dataAPI[i].Flow)

          I2.push(dataAPI[i].I)
          V2.push(dataAPI[i].V)
          Pow2.push(dataAPI[i].Elec_Pow2)
          COP12.push(dataAPI[i].COP1)
        }
      }
      //gauge chart
      // Inisialisasi echarts dengan ref
      const chart = echarts.init(gaugeChart.value);

      // Contoh konfigurasi chart
      const option = {
        tooltip: {
          formatter: function (params) {
            return 'COP : ' + params.value; // Menampilkan nilai dalam tooltip
          }
        },
        series: [{
          type: 'gauge',
          startAngle: 180,
          endAngle: 0,
          min: 0,
          max: 6,
          splitNumber: 12,
          axisLine: {
            lineStyle: {
              width: 6,
              color: [
                [0.45, '#FF6F61'], // red for 0-25%
                [0.5, '#FFB347'],  // orange for 25-50%
                [1, '#4CAF50']     // green for 75-100%
              ]
            }
          },
          pointer: {
            icon: 'rect',
            width: 6,
            length: '80%',
            offsetCenter: [0, '8%']
          },
          axisTick: {
            length: 12,
            lineStyle: {
              color: 'auto',
              width: 2
            }
          },
          splitLine: {
            length: 20,
            lineStyle: {
              color: 'auto',
              width: 5
            }
          },
          axisLabel: {
            color: 'auto',
            fontSize: 12,
            distance: -50,
            rotate: 'tangential',
            min: 0,
            max: 6,
          },
          title: {
            offsetCenter: [0, '-40%'],
            fontSize: 24
          },
          detail: {
            fontSize: 40,
            offsetCenter: [0, '-30%'],
            valueAnimation: true,
            color: 'auto'
          },
          data: [{
            value: COP1,
            name: ''
          }]
        }]
      };

      // Setel opsi chart
      chart.setOption(option);

      // Inisialisasi echarts dengan ref
      const chart2 = echarts.init(gaugeChart2.value);

      // Contoh konfigurasi chart
      const option2 = {
        tooltip: {
          formatter: function (params) {
            return 'COP : ' + params.value; // Menampilkan nilai dalam tooltip
          }
        },
        series: [{
          type: 'gauge',
          startAngle: 180,
          endAngle: 0,
          min: 0,
          max: 6,
          splitNumber: 12,
          axisLine: {
            lineStyle: {
              width: 6,
              color: [
                [0.45, '#FF6F61'], // red for 0-25%
                [0.5, '#FFB347'],  // orange for 25-50%
                [1, '#4CAF50']     // green for 75-100%
              ]
            }
          },
          pointer: {
            icon: 'rect',
            width: 6,
            length: '80%',
            offsetCenter: [0, '8%']
          },
          axisTick: {
            length: 12,
            lineStyle: {
              color: 'auto',
              width: 2
            }
          },
          splitLine: {
            length: 20,
            lineStyle: {
              color: 'auto',
              width: 5
            }
          },
          axisLabel: {
            color: 'auto',
            fontSize: 12,
            distance: -50,
            rotate: 'tangential',
            min: 0,
            max: 6,
          },
          title: {
            offsetCenter: [0, '-40%'],
            fontSize: 24
          },
          detail: {
            fontSize: 40,
            offsetCenter: [0, '-30%'],
            valueAnimation: true,
            color: 'auto'
          },
          data: [{
            value: COP2,
            name: ''
          }],
        }]
      };

      // Setel opsi chart
      chart2.setOption(option2);

      chartOptions1.value = {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          fontFamily: "Montserrat, Helvetica, Arial, serif",
        },
        theme: {
          mode: (localStorage.getItem('vuexy-skin') === null) ? 'dark' : localStorage.getItem('vuexy-skin')
        },
        colors: ["#33B2DF", "#FF4560", "#13D8AA", "#F9CE1D", "#4CAF50", "#546E7A"],
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [5, 5, 5, 5],
          curve: 'smooth',
          // dashArray: [0, 8, 5]
        },
        title: {
          text: '',
          align: 'left'
        },
        legend: {
          tooltipHoverFormatter: function (val, opts) {
            return val + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>'
          }
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6
          }
        },
        xaxis: {
          tooltip: {
            enabled: true,
            formatter: function (value) {
              const date = new Date(value);
              let hours = date.getHours();
              let minute = date.getMinutes();
              return `${hours}:${minute}`;
            }
          },
          categories: categories1,
          type: 'datetime',
          labels: {
            showDuplicate: false,
            formatter: (function () {
              let previousHour0 = null;
              let previousHour = null;
              let previousIndex = null;
              return function (value, timestamp, index) {
                const date = new Date(timestamp);
                let hours = date.getHours();
                // Pad with leading zero if necessary
                if (hours < 10) hours = '0' + hours;

                // Show label only if it's the first data point or if the hour has changed
                //console.log(index.i +'--'+ previousHour +'--'+ hours)
                if (index.i === 0) {
                  previousHour0 = hours;
                  return `${hours}:00`;
                } else if (previousIndex === index.i && previousHour === hours && previousHour0 !== hours) {
                  previousHour = hours;
                  previousHour0 = hours;
                  previousIndex = index.i;
                  return `${hours}:00`;
                }
                previousHour = hours;
                previousIndex = index.i;
                // else {
                return '';
                // }
              };
            })()
          },
          tickAmount: 24, // Set to 24 to show each hour
        },
        tooltip: {
          x: {
            show: true,
            // formatter: (value) => { const date = new Date(value); return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`; },
            formatter: function (value) {
              const date = new Date(value);
              const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
              const formattedDate = date.toLocaleDateString('en-US', options);
              return formattedDate;
            }
          },
          y: [
            {
              title: {
                formatter: function (val) {
                  return val
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val;
                }
              }
            }
          ]
        },
        grid: {
          borderColor: '#f1f1f1',
        },
      }

      series1.value = [
        {
          name: "Tin(℃)",
          data: Tin1
        },
        {
          name: "Tout(℃)",
          data: Tout1
        },
        {
          name: '△T(℃) ',
          data: T1
        },
        {
          name: 'Flow(L/s)',
          data: Flow1
        }
      ]


      chartOptions2.value = {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          fontFamily: "Montserrat, Helvetica, Arial, serif",
        },
        theme: {
          mode: (localStorage.getItem('vuexy-skin') === null) ? 'dark' : localStorage.getItem('vuexy-skin')
        },
        colors: ["#33B2DF", "#FF4560", "#13D8AA", "#F9CE1D", "#4CAF50", "#546E7A"],
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [5, 5, 5],
          curve: 'smooth',
          // dashArray: [0, 8, 5]
        },
        title: {
          text: '',
          align: 'left'
        },
        legend: {
          tooltipHoverFormatter: function (val, opts) {
            return val + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>'
          }
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6
          }
        },
        xaxis: {
          tooltip: {
            enabled: true,
            formatter: function (value) {
              const date = new Date(value);
              let hours = date.getHours();
              let minute = date.getMinutes();
              return `${hours}:${minute}`;
            }
          },
          categories: categories1,
          type: 'datetime',
          labels: {
            showDuplicate: false,
            formatter: (function () {
              let previousHour0 = null;
              let previousHour = null;
              let previousIndex = null;
              return function (value, timestamp, index) {
                const date = new Date(timestamp);
                let hours = date.getHours();
                // Pad with leading zero if necessary
                if (hours < 10) hours = '0' + hours;

                // Show label only if it's the first data point or if the hour has changed
                //console.log(index.i +'--'+ previousHour +'--'+ hours)
                if (index.i === 0) {
                  previousHour0 = hours;
                  return `${hours}:00`;
                } else if (previousIndex === index.i && previousHour === hours && previousHour0 !== hours) {
                  previousHour = hours;
                  previousHour0 = hours;
                  previousIndex = index.i;
                  return `${hours}:00`;
                }
                previousHour = hours;
                previousIndex = index.i;
                // else {
                return '';
                // }
              };
            })()
          },
          tickAmount: 24, // Set to 24 to show each hour
        },
        tooltip: {
          x: {
            show: true,
            // formatter: (value) => { const date = new Date(value); return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`; },
            formatter: function (value) {
              const date = new Date(value);
              const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
              const formattedDate = date.toLocaleDateString('en-US', options);
              return formattedDate;
            }
          },
          y: [
            {
              title: {
                formatter: function (val) {
                  return val
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val;
                }
              }
            }
          ]
        },
        grid: {
          borderColor: '#f1f1f1',
        },
      }

      series2.value = [
        {
          name: "I(A)",
          data: I1
        },
        {
          name: "Volt",
          data: V1
        },
        {
          name: 'Pow(kW) ',
          data: Pow1
        },
      ]

      chartOptions3.value = {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          fontFamily: "Montserrat, Helvetica, Arial, serif",
        },
        theme: {
          mode: (localStorage.getItem('vuexy-skin') === null) ? 'dark' : localStorage.getItem('vuexy-skin')
        },
        colors: ["#33B2DF"],
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [5],
          curve: 'smooth',
          // dashArray: [0, 8, 5]
        },
        title: {
          text: '',
          align: 'left'
        },
        // legend: {
        //   tooltipHoverFormatter: function(val, opts) {
        //     return val + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>'
        //   }
        // },
        legend: {
          show: true,
          horizontalAlign: 'center',
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: '#fff',
            radius: 12,
            offsetX: 0,
            offsetY: 0
          },
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6
          }
        },
        xaxis: {
          tooltip: {
            enabled: true,
            formatter: function (value) {
              const date = new Date(value);
              let hours = date.getHours();
              let minute = date.getMinutes();
              return `${hours}:${minute}`;
            }
          },
          categories: categories1,
          type: 'datetime',
          labels: {
            showDuplicate: false,
            formatter: (function () {
              let previousHour0 = null;
              let previousHour = null;
              let previousIndex = null;
              return function (value, timestamp, index) {
                const date = new Date(timestamp);
                let hours = date.getHours();
                // Pad with leading zero if necessary
                if (hours < 10) hours = '0' + hours;

                // Show label only if it's the first data point or if the hour has changed
                //console.log(index.i +'--'+ previousHour +'--'+ hours)
                if (index.i === 0) {
                  previousHour0 = hours;
                  return `${hours}:00`;
                } else if (previousIndex === index.i && previousHour === hours && previousHour0 !== hours) {
                  previousHour = hours;
                  previousHour0 = hours;
                  previousIndex = index.i;
                  return `${hours}:00`;
                }
                previousHour = hours;
                previousIndex = index.i;
                // else {
                return '';
                // }
              };
            })()
          },
          tickAmount: 24, // Set to 24 to show each hour
        },
        tooltip: {
          x: {
            show: true,
            // formatter: (value) => { const date = new Date(value); return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`; },
            formatter: function (value) {
              const date = new Date(value);
              const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
              const formattedDate = date.toLocaleDateString('en-US', options);
              return formattedDate;
            }
          },
          y: [
            {
              title: {
                formatter: function (val) {
                  return val
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val;
                }
              }
            }
          ]
        },
        grid: {
          borderColor: '#f1f1f1',
        },
      }

      series3.value = [
        {
          name: "COP",
          data: COP11
        },
      ]

      chartOptions4.value = {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          fontFamily: "Montserrat, Helvetica, Arial, serif",
        },
        theme: {
          mode: (localStorage.getItem('vuexy-skin') === null) ? 'dark' : localStorage.getItem('vuexy-skin')
        },
        colors: ["#33B2DF", "#FF4560", "#13D8AA", "#F9CE1D", "#4CAF50", "#546E7A"],
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [5, 7, 5, 5],
          curve: 'smooth',
          // dashArray: [0, 8, 5]
        },
        title: {
          text: '',
          align: 'left'
        },
        legend: {
          tooltipHoverFormatter: function (val, opts) {
            return val + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>'
          }
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6
          }
        },
        xaxis: {
          tooltip: {
            enabled: true,
            formatter: function (value) {
              const date = new Date(value);
              let hours = date.getHours();
              let minute = date.getMinutes();
              return `${hours}:${minute}`;
            }
          },
          categories: categories1,
          type: 'datetime',
          labels: {
            showDuplicate: false,
            formatter: (function () {
              let previousHour0 = null;
              let previousHour = null;
              let previousIndex = null;
              return function (value, timestamp, index) {
                const date = new Date(timestamp);
                let hours = date.getHours();
                // Pad with leading zero if necessary
                if (hours < 10) hours = '0' + hours;

                // Show label only if it's the first data point or if the hour has changed
                //console.log(index.i +'--'+ previousHour +'--'+ hours)
                if (index.i === 0) {
                  previousHour0 = hours;
                  return `${hours}:00`;
                } else if (previousIndex === index.i && previousHour === hours && previousHour0 !== hours) {
                  previousHour = hours;
                  previousHour0 = hours;
                  previousIndex = index.i;
                  return `${hours}:00`;
                }
                previousHour = hours;
                previousIndex = index.i;
                // else {
                return '';
                // }
              };
            })()
          },
          tickAmount: 24, // Set to 24 to show each hour
        },
        tooltip: {
          x: {
            show: true,
            // formatter: (value) => { const date = new Date(value); return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`; },
            formatter: function (value) {
              const date = new Date(value);
              const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
              const formattedDate = date.toLocaleDateString('en-US', options);
              return formattedDate;
            }
          },
          y: [
            {
              title: {
                formatter: function (val) {
                  return val
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val;
                }
              }
            }
          ]
        },
        grid: {
          borderColor: '#f1f1f1',
        },
      }

      series4.value = [
        {
          name: "Temperatur Tank(℃)",
          data: TT1
        },
      ]


      ///Chiller 2 chart
      chartOptions5.value = {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          fontFamily: "Montserrat, Helvetica, Arial, serif",
        },
        theme: {
          mode: (localStorage.getItem('vuexy-skin') === null) ? 'dark' : localStorage.getItem('vuexy-skin')
        },
        colors: ["#33B2DF", "#FF4560", "#13D8AA", "#F9CE1D", "#4CAF50", "#546E7A"],
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [5, 5, 5, 5],
          curve: 'smooth',
          // curve: 'straight',
          // dashArray: [0, 8, 5]
        },
        title: {
          text: '',
          align: 'left'
        },
        legend: {
          tooltipHoverFormatter: function (val, opts) {
            return val + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>'
          }
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6
          }
        },
        xaxis: {
          tooltip: {
            enabled: true,
            formatter: function (value) {
              const date = new Date(value);
              let hours = date.getHours();
              let minute = date.getMinutes();
              return `${hours}:${minute}`;
            }
          },
          categories: categories1,
          type: 'datetime',
          labels: {
            showDuplicate: false,
            formatter: (function () {
              let previousHour0 = null;
              let previousHour = null;
              let previousIndex = null;
              return function (value, timestamp, index) {
                const date = new Date(timestamp);
                let hours = date.getHours();
                // Pad with leading zero if necessary
                if (hours < 10) hours = '0' + hours;

                // Show label only if it's the first data point or if the hour has changed
                //console.log(index.i +'--'+ previousHour +'--'+ hours)
                if (index.i === 0) {
                  previousHour0 = hours;
                  return `${hours}:00`;
                } else if (previousIndex === index.i && previousHour === hours && previousHour0 !== hours) {
                  previousHour = hours;
                  previousHour0 = hours;
                  previousIndex = index.i;
                  return `${hours}:00`;
                }
                previousHour = hours;
                previousIndex = index.i;
                // else {
                return '';
                // }
              };
            })()
          },
          tickAmount: 24, // Set to 24 to show each hour
        },
        tooltip: {
          x: {
            show: true,
            // formatter: (value) => { const date = new Date(value); return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`; },
            formatter: function (value) {
              const date = new Date(value);
              const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
              const formattedDate = date.toLocaleDateString('en-US', options);
              return formattedDate;
            }
          },
          y: [
            {
              title: {
                formatter: function (val) {
                  return val
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val;
                }
              }
            }
          ]
        },
        grid: {
          borderColor: '#f1f1f1',
        },
      }

      series5.value = [
        {
          name: "Tin(℃)",
          data: Tin2
        },
        {
          name: "Tout(℃)",
          data: Tout2
        },
        {
          name: '△T(℃) ',
          data: T2
        },
        {
          name: 'Flow(L/s)',
          data: Flow2
        }
      ]


      chartOptions6.value = {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          fontFamily: "Montserrat, Helvetica, Arial, serif",
        },
        theme: {
          mode: (localStorage.getItem('vuexy-skin') === null) ? 'dark' : localStorage.getItem('vuexy-skin')
        },
        colors: ["#33B2DF", "#FF4560", "#13D8AA", "#F9CE1D", "#4CAF50", "#546E7A"],
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [5, 5, 5],
          curve: 'smooth',
          // dashArray: [0, 8, 5]
        },
        title: {
          text: '',
          align: 'left'
        },
        legend: {
          tooltipHoverFormatter: function (val, opts) {
            return val + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>'
          }
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6
          }
        },
        xaxis: {
          tooltip: {
            enabled: true,
            formatter: function (value) {
              const date = new Date(value);
              let hours = date.getHours();
              let minute = date.getMinutes();
              return `${hours}:${minute}`;
            }
          },
          categories: categories1,
          type: 'datetime',
          labels: {
            showDuplicate: false,
            formatter: (function () {
              let previousHour0 = null;
              let previousHour = null;
              let previousIndex = null;
              return function (value, timestamp, index) {
                const date = new Date(timestamp);
                let hours = date.getHours();
                // Pad with leading zero if necessary
                if (hours < 10) hours = '0' + hours;

                // Show label only if it's the first data point or if the hour has changed
                //console.log(index.i +'--'+ previousHour +'--'+ hours)
                if (index.i === 0) {
                  previousHour0 = hours;
                  return `${hours}:00`;
                } else if (previousIndex === index.i && previousHour === hours && previousHour0 !== hours) {
                  previousHour = hours;
                  previousHour0 = hours;
                  previousIndex = index.i;
                  return `${hours}:00`;
                }
                previousHour = hours;
                previousIndex = index.i;
                // else {
                return '';
                // }
              };
            })()
          },
          tickAmount: 24, // Set to 24 to show each hour
        },
        tooltip: {
          x: {
            show: true,
            // formatter: (value) => { const date = new Date(value); return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`; },
            formatter: function (value) {
              const date = new Date(value);
              const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
              const formattedDate = date.toLocaleDateString('en-US', options);
              return formattedDate;
            }
          },
          y: [
            {
              title: {
                formatter: function (val) {
                  return val
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val;
                }
              }
            }
          ]
        },
        grid: {
          borderColor: '#f1f1f1',
        },
      }

      series6.value = [
        {
          name: "I(A)",
          data: I2
        },
        {
          name: "Volt",
          data: V2
        },
        {
          name: 'Pow(kW) ',
          data: Pow2
        },
      ]

      chartOptions7.value = {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          fontFamily: "Montserrat, Helvetica, Arial, serif",
        },
        theme: {
          mode: (localStorage.getItem('vuexy-skin') === null) ? 'dark' : localStorage.getItem('vuexy-skin')
        },
        colors: ["#33B2DF", "#FF4560", "#13D8AA", "#F9CE1D", "#4CAF50", "#546E7A"],
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [5, 7, 5, 5],
          curve: 'smooth',
          // dashArray: [0, 8, 5]
        },
        title: {
          text: '',
          align: 'left'
        },
        legend: {
          tooltipHoverFormatter: function (val, opts) {
            return val + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>'
          }
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6
          }
        },
        xaxis: {
          tooltip: {
            enabled: true,
            formatter: function (value) {
              const date = new Date(value);
              let hours = date.getHours();
              let minute = date.getMinutes();
              return `${hours}:${minute}`;
            }
          },
          categories: categories1,
          type: 'datetime',
          labels: {
            showDuplicate: false,
            formatter: (function () {
              let previousHour0 = null;
              let previousHour = null;
              let previousIndex = null;
              return function (value, timestamp, index) {
                const date = new Date(timestamp);
                let hours = date.getHours();
                // Pad with leading zero if necessary
                if (hours < 10) hours = '0' + hours;

                // Show label only if it's the first data point or if the hour has changed
                //console.log(index.i +'--'+ previousHour +'--'+ hours)
                if (index.i === 0) {
                  previousHour0 = hours;
                  return `${hours}:00`;
                } else if (previousIndex === index.i && previousHour === hours && previousHour0 !== hours) {
                  previousHour = hours;
                  previousHour0 = hours;
                  previousIndex = index.i;
                  return `${hours}:00`;
                }
                previousHour = hours;
                previousIndex = index.i;
                // else {
                return '';
                // }
              };
            })()
          },
          tickAmount: 24, // Set to 24 to show each hour
        },
        tooltip: {
          x: {
            show: true,
            // formatter: (value) => { const date = new Date(value); return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`; },
            formatter: function (value) {
              const date = new Date(value);
              const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
              const formattedDate = date.toLocaleDateString('en-US', options);
              return formattedDate;
            }
          },
          y: [
            {
              title: {
                formatter: function (val) {
                  return val
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val;
                }
              }
            }
          ]
        },
        grid: {
          borderColor: '#f1f1f1',
        },
      }

      series7.value = [
        {
          name: "COP",
          data: COP12
        },
      ]

      //untuk bar chart COP
      const result2 = await axios.get(`${useAppConfig().AxiosUrl}api_mes/chillers-cop/view`)
      const dataAPI2 = result2.data.data
      for (let i = 0, len = dataAPI2.length; i < len; i++) {
        if (dataAPI2[i].Chiller === 'C1') {
          categoriesBar.push(dataAPI2[i].Tanggal)
          COP321.push(dataAPI2[i].COP32)
          COP351.push(dataAPI2[i].COP35)
        } else {
          COP322.push(dataAPI2[i].COP32)
          COP352.push(dataAPI2[i].COP35)
        }
      }

      chartOptions8.value = {
        chart: {
          type: 'bar',
          height: 350,
          zoom: {
            enabled: false
          },
          fontFamily: "Montserrat, Helvetica, Arial, serif",
        },
        theme: {
          mode: (localStorage.getItem('vuexy-skin') === null) ? 'dark' : localStorage.getItem('vuexy-skin')
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: categoriesBar,
          type: 'datetime',
          labels: {
            formatter: (value) => {
              const date = new Date(value);
              return date.toLocaleDateString('en-GB', {
                day: 'numeric',
                month: 'short'
              });
            }
          }
        },
        yaxis: {
          title: {
            text: '%'
          }
        },
        fill: {
          opacity: 1
        },
        colors: ["#33B2DF", "#FF4560", "#13D8AA", "#F9CE1D", "#4CAF50", "#546E7A"],
        legend: {
          show: false,
          horizontalAlign: 'center',
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: '#fff',
            radius: 12,
            offsetX: 0,
            offsetY: 0
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
          x: {
            show: true,
            // formatter: (value) => { const date = new Date(value); return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`; },
            formatter: function (value) {
              const date = new Date(value);
              const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
              const formattedDate = date.toLocaleDateString('en-US', options);
              return formattedDate;
            }
          },
          y: {
            formatter: function (val) {
              return val
            }
          }
        },
        legend: {
          show: true,
          horizontalAlign: 'center',
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: '#fff',
            radius: 12,
            offsetX: 0,
            offsetY: 0
          },
        },
      }

      series8.value = [{
        name: 'COP3.2(%)',
        data: COP321
      }, {
        name: 'COP3.5(%)',
        data: COP351
      },]

      chartOptions9.value = {
        chart: {
          type: 'bar',
          height: 350,
          zoom: {
            enabled: false
          },
          fontFamily: "Montserrat, Helvetica, Arial, serif",
        },
        theme: {
          mode: (localStorage.getItem('vuexy-skin') === null) ? 'dark' : localStorage.getItem('vuexy-skin')
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: categoriesBar,
          type: 'datetime',
          labels: {
            formatter: (value) => {
              const date = new Date(value);
              return date.toLocaleDateString('en-GB', {
                day: 'numeric',
                month: 'short'
              });
            }
          }
        },
        yaxis: {
          title: {
            text: '%'
          }
        },
        fill: {
          opacity: 1
        },
        colors: ["#33B2DF", "#FF4560", "#13D8AA", "#F9CE1D", "#4CAF50", "#546E7A"],
        legend: {
          show: false,
          horizontalAlign: 'center',
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: '#fff',
            radius: 12,
            offsetX: 0,
            offsetY: 0
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
          x: {
            show: true,
            // formatter: (value) => { const date = new Date(value); return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`; },
            formatter: function (value) {
              const date = new Date(value);
              const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
              const formattedDate = date.toLocaleDateString('en-US', options);
              return formattedDate;
            }
          },
          y: {
            formatter: function (val) {
              return val
            }
          }
        },
        legend: {
          show: true,
          horizontalAlign: 'center',
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: '#fff',
            radius: 12,
            offsetX: 0,
            offsetY: 0
          },
        },
      }

      series9.value = [{
        name: 'COP3.2(%)',
        data: COP322
      }, {
        name: 'COP3.5(%)',
        data: COP352
      },]
    })

    const {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      roleFilter,
      planFilter,
      statusFilter,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
    } = useList()

    return {
      userData,
      AddData,
      EditData,

      gaugeChart,
      gaugeChart2,

      COP1,
      COP2,
      Chillers,
      COP1_detail,
      COP2_detail,

      chartOptions1,
      series1,

      chartOptions2,
      series2,

      chartOptions3,
      series3,

      chartOptions4,
      series4,

      chartOptions5,
      series5,

      chartOptions6,
      series6,

      chartOptions7,
      series7,

      chartOptions8,
      series8,

      chartOptions9,
      series9,

      // Sidebar
      isAddNewUserSidebarActive,

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      roleOptions,
      roleFilter,
      planFilter,
      statusFilter,

      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
    }
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$refs.gaugeChart);
      this.chart.setOption({
        toolbox: {
          feature: {
            saveAsImage: {
              show: false,
              title: 'Save as image'
            }
          }
        },
        series: [
          {
            name: 'Gauge',
            type: 'gauge',
            // startAngle: 180, // Set start angle to 180 degrees
            // endAngle: 0, // Set end angle to 0 degrees
            startAngle: 200,
            endAngle: -20,
            detail: { formatter: '{value}' },
            data: [{ value: this.COP1, name: 'COP', }],
            min: 0,
            max: 6,
            fontSize: 20,
            // radius: '100%',
            axisLabel: {
              // color: '#464646',
              fontSize: 12,
              distance: -40,
              rotate: 'tangential',
            },
            axisLine: {
              lineStyle: {
                width: 50,
                color: [
                  [0.45, '#FF6F61'], // red for 0-25%
                  [0.5, '#FFB347'],  // orange for 25-50%
                  [1, '#4CAF50']     // green for 75-100%
                ]
              }
            },
            splitLine: {
              length: 15, // Adjust the length of split lines
              lineStyle: {
                color: 'auto'
              }
            },
            pointer: {
              width: 5,
              itemStyle: {
                color: '#7CFFB2' // Set the color here
              }
            },
            title: {
              show: true,
              offsetCenter: ['0%', '-30%'] // Adjust title position
            }
          },
        ]
      });
    },
    initChart2() {
      this.chart = echarts.init(this.$refs.gaugeChart2);
      this.chart.setOption({
        toolbox: {
          feature: {
            saveAsImage: {
              show: false,
              title: 'Save as image'
            }
          }
        },
        series: [
          {
            name: 'Gauge',
            type: 'gauge',
            // startAngle: 180, // Set start angle to 180 degrees
            // endAngle: 0, // Set end angle to 0 degrees
            startAngle: 200,
            endAngle: -20,
            detail: { formatter: '{value}' },
            data: [{ value: this.COP2, name: 'COP', }],
            min: 0,
            max: 6,
            fontSize: 20,
            // radius: '100%',
            axisLabel: {
              // color: '#464646',
              fontSize: 12,
              distance: -40,
              rotate: 'tangential',
            },
            axisLine: {
              lineStyle: {
                width: 50,
                color: [
                  [0.45, '#FF6F61'], // red for 0-25%
                  [0.5, '#FFB347'],  // orange for 25-50%
                  [1, '#4CAF50']     // green for 75-100%
                ]
              }
            },
            splitLine: {
              length: 15, // Adjust the length of split lines
              lineStyle: {
                color: 'auto'
              }
            },
            pointer: {
              width: 5,
              itemStyle: {
                color: '#7CFFB2' // Set the color here
              }
            },
            title: {
              show: true,
              offsetCenter: ['0%', '-30%'] // Adjust title position
            }
          },
        ]
      });
    },
    updateChart(newValue) {
      this.chart.setOption({
        series: [
          {
            data: [{ value: newValue, name: 'Value' }]
          }
        ]
      });
    },
    updateChart2(newValue) {
      this.chart2.setOption({
        series: [
          {
            data: [{ value: newValue, name: 'Value' }]
          }
        ]
      });
    },
  },
}
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
